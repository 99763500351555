import React from "react"
import PropTypes from "prop-types"
import Box from "../../components/box"
import Divider from "../../components/divider"
import Heading from "../../components/heading"
import Small from "../../components/small"
import Stack from "../../components/stack"
import Text from "../../components/text"
import TextLink from "../../components/textlink"

const FuehrungenKontakt = ({ forSchools }) => {
  const subject = `Führungsanfrage`
  const message = `Liebes Team des Hölderlinturms,%0D%0A%0D%0A hiermit möchten wir gerne folgendes Angebot buchen:%0D%0A%0D%0A Führung/Workshop:%0D%0A Gruppe:%0D%0A Gruppengröße:%0D%0A%0D%0A [Bitte beachten Sie: Gruppen ab 15 Personen werden aufgrund der begrenzten Raumkapazitäten geteilt.] %0D%0A%0D%0A Wunschtermin mit Datum und Uhrzeit:%0D%0A Alternativtermin mit Datum und Uhrzeit:%0D%0A %0D%0A%0D%0A Anmerkungen:%0D%0A %0D%0A%0D%0A Institution: %0D%0A Vorname: %0D%0A Name: %0D%0A Straße und Hausnr.: %0D%0A PLZ: %0D%0A Ort: %0D%0A Telefonische Erreichbarkeit am Tag der Buchung: %0D%0A Email-Adresse: %0D%0A`
  const messageSchools = `Liebes Team des Hölderlinturms,%0D%0A%0D%0A hiermit möchten wir gerne folgendes Angebot buchen:%0D%0A%0D%0A Führung/Workshop: %0D%0A Gruppe:%0D%0A Gruppengröße:%0D%0A%0D%0A Wunschtermin mit Datum und Uhrzeit:%0D%0A Alternativtermin mit Datum und Uhrzeit:%0D%0A %0D%0A%0D%0A Anmerkungen:%0D%0A %0D%0A%0D%0A Institution: %0D%0A Vorname: %0D%0A Name: %0D%0A Straße und Hausnr.: %0D%0A PLZ: %0D%0A Ort: %0D%0A Telefonische Erreichbarkeit am Tag der Buchung: %0D%0A Email-Adresse: %0D%0A`

  return (
    <Box p={6} bg="muted">
      <Stack space={6}>
        <Heading as="h2" level={5}>
          Kontakt
        </Heading>
        <Text>Für Rückfragen stehen wir Ihnen gerne zur Verfügung.</Text>
        <TextLink
          href={`mailto:fuehrungen-hoelderlinturm@tuebingen.de?subject=${subject}&body=${
            forSchools ? messageSchools : message
          }`}
        >
          fuehrungen-hoelderlinturm@tuebingen.de
        </TextLink>
        <Divider size={4} />
        <Small color="whisper">
          Bitte buchen Sie alle Führungen und Workshops mindestens 14 Tage im
          Voraus.
        </Small>
      </Stack>
    </Box>
  )
}

FuehrungenKontakt.propTypes = {
  forSchools: PropTypes.bool,
}

FuehrungenKontakt.defaultProps = {
  forSchools: false,
}

export default FuehrungenKontakt
