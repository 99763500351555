import React from "react"
import PropTypes from "prop-types"
import Button from "../../components/button"
import Paragraph from "../../components/paragraph"
import BulletList from "../../components/bullet-list"
import Bullet from "../../components/bullet"
import Heading from "../../components/heading"
import Stack from "../../components/stack"
import Inline from "../../components/inline"
import Text from "../../components/text"

const Fuehrung = ({
  bookable,
  category,
  description,
  duration,
  headingElement,
  features,
  price,
  forSchools,
  subtitle,
  title,
}) => {
  const subject = `Führungsanfrage: ${title}`
  const message = `Liebes Team des Hölderlinturms,%0D%0A%0D%0A hiermit möchten wir gerne folgendes Angebot buchen:%0D%0A%0D%0A Führung/Workshop: ${title}%0D%0A Gruppe:%0D%0A Gruppengröße:%0D%0A%0D%0A [Bitte beachten Sie: Gruppen ab 15 Personen werden aufgrund der begrenzten Raumkapazitäten geteilt.] %0D%0A%0D%0A Wunschtermin mit Datum und Uhrzeit:%0D%0A Alternativtermin mit Datum und Uhrzeit:%0D%0A %0D%0A%0D%0A Anmerkungen:%0D%0A %0D%0A%0D%0A Institution: %0D%0A Vorname: %0D%0A Name: %0D%0A Straße und Hausnr.: %0D%0A PLZ: %0D%0A Ort: %0D%0A Telefonische Erreichbarkeit am Tag der Buchung: %0D%0A Email-Adresse: %0D%0A`
  const messageSchools = `Liebes Team des Hölderlinturms,%0D%0A%0D%0A hiermit möchten wir gerne folgendes Angebot buchen:%0D%0A%0D%0A Führung/Workshop: ${title}%0D%0A Gruppe:%0D%0A Gruppengröße:%0D%0A%0D%0A Wunschtermin mit Datum und Uhrzeit:%0D%0A Alternativtermin mit Datum und Uhrzeit:%0D%0A %0D%0A%0D%0A Anmerkungen:%0D%0A %0D%0A%0D%0A Institution: %0D%0A Vorname: %0D%0A Name: %0D%0A Straße und Hausnr.: %0D%0A PLZ: %0D%0A Ort: %0D%0A Telefonische Erreichbarkeit am Tag der Buchung: %0D%0A Email-Adresse: %0D%0A`

  return (
    <Stack space={6}>
      <Stack space={3}>
        <Heading as={headingElement} level={4}>
          {title}
        </Heading>
        {subtitle && (
          <Paragraph size={[2, 3, 4]} as="span" color="whisper">
            {subtitle}
          </Paragraph>
        )}
      </Stack>
      <Paragraph size={[2, 3, 4]}>{description}</Paragraph>
      {features && (
        <Stack space={3}>
          <Paragraph size={[2, 3, 4]} as="span">
            Anknüpfung an den Bildungsplan:
          </Paragraph>
          <BulletList size={[2, 3, 4]}>
            {features.map((feature, index) => (
              <Bullet key={index}>{feature}</Bullet>
            ))}
          </BulletList>
        </Stack>
      )}
      <Inline alignY="center" space={6}>
        {duration && (
          <Text color="whisper" sans size={3}>
            {duration}
          </Text>
        )}
        {price && (
          <Text color="whisper" sans size={3}>
            {price}
          </Text>
        )}
        {category && (
          <Text color="whisper" sans size={3}>
            {category}
          </Text>
        )}
        {bookable === true && (
          <Button
            secondary
            href={`mailto:fuehrungen-hoelderlinturm@tuebingen.de?subject=${subject}&body=${
              forSchools ? messageSchools : message
            }`}
          >
            Jetzt anmelden
          </Button>
        )}
      </Inline>
    </Stack>
  )
}

Fuehrung.propTypes = {
  bookable: PropTypes.bool,
  category: PropTypes.string,
  description: PropTypes.string,
  duration: PropTypes.string,
  features: PropTypes.array,
  forSchools: PropTypes.bool,
  headingElement: PropTypes.string,
  price: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

Fuehrung.defaultProps = {
  bookable: false,
  forSchools: false,
}

export default Fuehrung
